import logo from "./logo.svg";
import "./App.css";

const share = async (title, text, blob) => {
  const data = {
    files: [
      new File([blob], "image.png", {
        type: blob.type,
      }),
    ],
    title: title,
    text: text,
  };
  try {
    if (!navigator.canShare(data)) {
      throw new Error("Can't share data.", data);
    }
    await navigator.share(data);
  } catch (err) {
    console.error(err.name, err.message);
  }
};

function App() {
  const imgSrc = "https://og-image-cloudflare.evo-e91.workers.dev/";
  const shareClick = async () => {
    const blob = await fetch(imgSrc);
    share("your brands", "your brands", blob);
  };
  return (
    <div className="App">
      <header className="App-header">
        <img src={imgSrc} />
        <button onClick={shareClick}>Share</button>
      </header>
    </div>
  );
}

export default App;
